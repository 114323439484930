.footer {
  line-height: 2em;
  font-size: 1.2em;

  &__bar {
    min-height: 10px;
    background: $warning;
    background-repeat: repeat-y;
    background-size: 100% auto;
    background-attachment: fixed;
  }

  &__container {
    margin-top: 120px;
    margin-bottom: 200px;
  }

  &__menu {
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }

    li {
      @extend .flex-fill;
      align-self: center;
      display: block;
      text-align: center;

      a {
        text-decoration: none;
        padding: 20px 20px;
        font-weight: 700;
        @include media-breakpoint-up(md){
          color: white;
        }
        display: block;
        transition: ease 0.3s;

        &:hover {
          background: rgba(white, 0.8);
          color: $gray-700;
        }
      }
    }
  }

  &__social {
    font-size: 2em;
    color: rgba(black, 0.3);
    margin-left: 10px;
    margin-right: 10px;
  }
}
